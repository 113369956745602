<template>
  <div>
    <div class="logo-container">
      <b-row
        style="margin:0"
      >
        <b-col
          xl="2"
          lg="12"
          md="6"
          sm="12"
        >
          <div>
            <a
              class="logo-a"
              href="https://ebaoguo.com/"
            >
              <img
                class="logo"
                src="../../assets/images/logo/ebaoguo-logo.png"
                alt="logo"
              >
            </a>
          </div>
        </b-col>
        <b-col
          class="pc-nav"
          xl="10"
          lg="12"
          md="12"
          sm="12"
        >
          <div>
            <ul class="logo-ul">
              <li
                v-for="item in navList"
                :key="item.path"
                :class="item.name === '我的账户' ? 'logo-li logo-mine' : 'logo-li'"
              >
                <span @click.prevent="handleNavClick(item)">{{ item.name }}</span>
                <div class="logo-ol-div">
                  <ol
                    v-for="v in item.children"
                    :key="v.path"
                    class="logo-ol"
                  >
                    <span @click.prevent="handleNavClick(v)">{{ v.name }}</span>
                    <div class="logo-span-div">
                      <span
                        v-for="j in v.children"
                        :key="j.path"
                        class="logo-span"
                      >
                        <span @click.prevent="handleNavClick(j)">{{ j.name }}</span>
                      </span>
                    </div>
                  </ol>
                </div>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col
          class="mobile-nav"
        >
          <div class="nav-btn">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              v-b-toggle.collapse-header
              size="sm"
              variant="outline-secondary"
            >
              <feather-icon
                style="width: 20px;height: 20px;stroke-width: 1;color:#333;"
                icon="AlignJustifyIcon"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse
      id="collapse-header"
      style="background:#fff"
    >
      <b-card class="border mb-0">
        <ul class="collapse-ul">
          <li
            v-for="item in navList"
            :key="item.path"
          >
            <span @click="handleMobileClick(item)">{{ item.name }}</span>
            <feather-icon
              v-show="item.children.length"
              v-b-toggle:[`collapse-ol${item.name}`]
              style="margin-bottom:4px;margin-left:5px"
              icon="ChevronDownIcon"
            />
            <b-collapse
              :id="`collapse-ol${item.name}`"
              style="background:#fff"
            >
              <div class="collapse-ol-div">
                <ol
                  v-for="v in item.children"
                  :key="v.path"
                >
                  <span @click="handleMobileClick(v)">{{ v.name }}</span>
                  <feather-icon
                    v-show="v.children.length"
                    v-b-toggle:[`collapse-ol${v.name}`]
                    style="margin-bottom:4px;margin-left:5px"
                    icon="ChevronDownIcon"
                  />
                  <b-collapse
                    :id="`collapse-ol${v.name}`"
                    style="background:#fff"
                  >
                    <div class="collapse-ol-div">
                      <ol
                        v-for="j in v.children"
                        :key="j.path"
                      >
                        <span @click="handleMobileClick(j)">{{ j.name }}</span>
                      </ol>
                    </div>
                  </b-collapse>
                </ol>
              </div>
            </b-collapse>
          </li>
        </ul>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BCollapse, VBToggle, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCollapse,
    BCard,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      navList: [{
        name: '首页',
        path: 'https://ebaoguo.com/',
        children: [],
      }, {
        name: '帮助中心',
        path: 'https://help.ebaoguo.com/zh-CN/',
        children: [{
          name: '如何准备并填写包裹的申报表格',
          path: 'https://ebaoguo.com/prepare-customs-declaration/',
          children: [],
        }, {
          name: '常见问题',
          path: 'https://ebaoguo.com/general-faq/',
          children: [],
        }, {
          name: '如何开始',
          path: 'https://ebaoguo.com/getting-started/',
          children: [],
        }, {
          name: '代理点查看',
          path: 'https://ebaoguo.com/store-locator/',
          children: [],
        }, {
          name: '易包裹发货仓库概览',
          path: 'https://ebaoguo.com/ebaoguo-warehouse-location/',
          children: [],
        }, {
          name: '箱子包装',
          path: 'https://ebaoguo.com/preparing-boxes/',
          children: [],
        }],
      }, {
        name: '开始转运',
        path: '开始转运',
        children: [{
          name: '集运线路截单日查看',
          path: 'https://ebaoguo.com/schedule/',
          children: [],
        }, {
          name: '从澳大利亚发货',
          path: '从澳大利亚发货',
          children: [{
            name: '澳洲邮寄马来西亚集运线路',
            path: 'https://ebaoguo.com/shipping-from-australia-to-malaysia/',
            children: [],
          }, {
            name: '澳洲邮寄香港',
            path: 'https://ebaoguo.com/shipping-from-austrlia-to-hongkong/',
            children: [],
          }, {
            name: '澳洲邮寄新西兰',
            path: 'https://ebaoguo.com/shipping-from-australia-to-new-zealand/',
            children: [],
          }, {
            name: '澳洲直邮台湾集运线路',
            path: 'https://ebaoguo.com/shipping-from-austrlia-to-taiwan/',
            children: [],
          }, {
            name: '澳洲直邮新加坡集运线路',
            path: 'https://ebaoguo.com/shipping-from-austrlia-to-singapore/',
            children: [],
          }, {
            name: '澳洲邮寄中国',
            path: 'https://ebaoguo.com/shipping-from-australia-to-china/',
            children: [],
          }, {
            name: '澳洲邮寄行李到中国',
            path: 'https://ebaoguo.com/shipping-luggage-from-australia-to-china/',
            children: [],
          }],
        }, {
          name: '从中国发货',
          path: '从中国发货',
          children: [{
            name: '中国海运到澳洲',
            path: 'https://ebaoguo.com/shipping-from-china-to-australia-by-sea/',
            children: [],
          }, {
            name: '中国空运到澳洲',
            path: 'https://ebaoguo.com/shipping-from-china-to-australia-by-air/',
            children: [],
          }, {
            name: '中国邮寄新西兰',
            path: 'https://ebaoguo.com/shipping-from-china-to-new-zealand/',
            children: [],
          }, {
            name: '中国邮政EMS线路',
            path: 'https://ebaoguo.com/china-post-ems/',
            children: [],
          }, {
            name: '中国集运到马来西亚',
            path: 'https://ebaoguo.com/shipping-from-china-to-malaysia/',
            children: [],
          }, {
            name: '中国大陆邮寄台湾线路',
            path: 'https://ebaoguo.com/china-mainland-to-taiwan/',
            children: [],
          }, {
            name: '中国大陆邮寄香港',
            path: 'https://ebaoguo.com/shipping-from-china-mainland-to-hong-kong/',
            children: [],
          }, {
            name: '淘宝集运到新加坡',
            path: 'https://ebaoguo.com/shipping-from-china-to-singapore/',
            children: [],
          }],
        }, {
          name: '从马来西亚发货',
          path: '从马来西亚发货',
          children: [{
            name: '马来西亚邮寄澳洲特快专线',
            path: 'https://ebaoguo.com/shipping-from-malaysia-to-australia/',
            children: [],
          }, {
            name: '马来西亚集运台湾包税线路',
            path: 'https://ebaoguo.com/shipping-from-malaysia-to-taiwan/',
            children: [],
          }, {
            name: '马来西亚邮寄新加坡特快线路',
            path: 'https://ebaoguo.com/shipping-from-malaysia-to-singapore/',
            children: [],
          }, {
            name: '马来西亚邮寄新西兰特快专线',
            path: 'https://ebaoguo.com/shipping-from-malaysia-to-new-zealand/',
            children: [],
          }, {
            name: '马来西亚邮寄到泰国含税线',
            path: 'https://ebaoguo.com/shipping-from-malaysia-to-thailand/',
            children: [],
          }],
        }, {
          name: '从日本发货',
          path: '从日本发货',
          children: [{
            name: '如何使用易包裹转运做日本集运',
            path: 'https://ebaoguo.com/shopping-on-jp/',
            children: [],
          }, {
            name: '日本邮寄马来西亚集运线路',
            path: 'https://ebaoguo.com/shipping-from-japan-to-malaysia/',
            children: [],
          }, {
            name: '日本邮寄台湾集运线路',
            path: 'https://ebaoguo.com/shipping-from-japan-to-taiwan/',
            children: [],
          }, {
            name: '日本邮寄澳洲EMS空运线',
            path: 'https://ebaoguo.com/shipping-from-japan-to-australia-by-air/',
            children: [],
          }, {
            name: '日本邮政邮寄全世界',
            path: 'https://ebaoguo.com/japan_post_ems/',
            children: [],
          }, {
            name: '日本邮寄中国集运线',
            path: 'https://ebaoguo.com/shipping-from-janpan-to-china-by-air/',
            children: [],
          }],
        }, {
          name: '从台湾发货',
          path: '从台湾发货',
          children: [{
            name: '台湾邮寄新加坡集运线路',
            path: 'https://ebaoguo.com/shipping-from-taiwan-to-singapore/',
            children: [],
          }, {
            name: '台湾邮寄澳洲',
            path: 'https://ebaoguo.com/shipping-from-taiwan-to-australia/',
            children: [],
          }, {
            name: '台湾邮寄马来西亚集运线路',
            path: 'https://ebaoguo.com/shipping-from-taiwan-to-malaysia/',
            children: [],
          }],
        }],
      }, {
        name: '运费计算',
        path: 'https://cal.ebaoguo.com/',
        children: [],
      }, {
        name: '分享',
        path: 'https://ebaoguo.com/share/',
        children: [{
          name: '购物网站推荐',
          path: 'https://ebaoguo.com/selected-au-stores/',
          children: [],
        }, {
          name: '澳洲直邮',
          path: 'https://zayu.com.au/',
          children: [],
        }],
      }, {
        name: '我的账户',
        path: 'https://dashboard.ebaoguo.com/',
        children: [],
      }],
    }
  },
  methods: {
    handleNavClick(item) {
      if (item.path.indexOf('https') > -1) {
        window.open(item.path)
      }
    },
    handleMobileClick(item) {
      if (item.path.indexOf('https') > -1) {
        window.location.href = item.path
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.logo-container {
  background: #fff;
  border-bottom: 1px solid #e7e7e7;
  position: relative;
}
.logo-a {
  display: inline-block;
  height: 80px;
  margin: 11px 0;
  margin-left: 54px;
}
.logo {
  height: 100%;
}
.logo-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-right: 82px;
}
.logo-li,
.logo-mine {
  font-size: 16px;
  color: #161616;
  padding: 0 30px;
  line-height: 102px;
  cursor: pointer;
  position: relative;
  &:hover{
    color: #0095eb;
    .logo-ol-div{
      color: #333;
      display: block;
      top:80%;
    }
  }
}
.logo-mine {
  span{
    background-color: #ffbb06;
    border-radius: 10px;
    padding: 15px;
  }
}
.logo-ol-div{
  display: none;
  position: absolute;
  left: 50%;
  top:0;
  z-index: 9999;
  width: 100%;
  margin-left: -100px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
}
.logo-ol{
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 270px;
  background: #fff;
  text-align: left;
  padding:0 20px;
  position: relative;
  &:not(:last-child){
    border-bottom: 1px solid #eee;
  }
  &:hover{
    color: #0095eb;
    .logo-span-div{
      color: #333;
      display: block;
    }
  }
}
.logo-span-div{
  position: absolute;
  right: -220px;
  width: 230px;
  background: #fff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
  top:-1px;
  display: none;
  padding: 0 20px;
}
.logo-span{
  display: block;
  text-align: left;
  &:not(:last-child){
    border-bottom: 1px solid #eee;
  }
  &:hover{
    color: #0095eb;
  }
}
.mobile-nav{
  position: absolute;
  right: 0;
  top:50%;
  transform: translateY(-50%);
}
.nav-btn{
  position: absolute;
  right: 10px;
  top:50%;
  transform: translateY(-50%);
}
.btn{
  padding: 4px 8px;
  border: 1px solid #ddd !important;
}
.collapse-ul{
  list-style: none;
  text-align: right;
  li{
    margin: 10px 0;
  }
}
.collapse-ol-div{
  padding-right:15px;
  margin-top: 10px;
  border-right: 5px solid #ffbb06;
  ol{
    margin: 6px 0;
    padding: 0;
  }
}
@media screen and (min-width: 948px) {
  .pc-nav{
    display: block;
  }
  .mobile-nav{
    display: none;
  }
}
@media screen and (max-width: 947px) {
  .pc-nav{
    display: none;
  }
  .mobile-nav{
    display: block;
  }
}
@media screen and (max-width: 420px) {
  .logo{
    height: 90%;
  }
}
</style>
