/* eslint-disable */
import axios from 'axios'

axios.defaults.timeout = 50000
axios.defaults.baseURL = 'https://api.ebaoguo.com'
// axios.defaults.withCredentials = true

const http = {
  post: null,
  get: null,
}

const serialize = data => {
  const list = []
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}

// TODO: 错误处理,token

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  err => Promise.reject(err),
)

axios.interceptors.response.use(
  response => response,
  error =>
    // 对响应错误做点什么
    Promise.reject(error)
  ,
)

// TODO:isSerialize是否开启表单序列化
http.post = function post(url,
  params, isSerialize = false) {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'post',
      headers:{
        'Content-Type':'application/json;charset=UTF-8'
      },
      data: isSerialize ? serialize(params) : params,
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

http.get = function (url,
  params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params,
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log(err)
      reject(err)
    })
  })
}

export default http
